import React from "react"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import Spec from "../components/Spec"
import Layout from "../components/layout"

const Home = ({ data }) => {
  const pages = data.allMarkdownRemark.edges.map((e) => e.node)
  const specs = data.allOpenApiSpec.edges.map((e) => e.node)

  return (
    <Layout>
      <section>
        <p>
          The Identity Service is an Authentication solution for the Vanco
          platform. It is designed to replace all existing authentication
          processes in Vanco products with a centralized API for user auth.
        </p>
        <p>
          This API is based on the [OpenID Connect](http://openid.net/connect/)
          protocol. OpenID Connect is an identity layer built on top of OAuth
          2.0. Because of this, the Identity Service will be fully capable of
          performing user authorization in addition to user authentication,
          although we expect that existing applications will keep their existing
          authorization code, and use us solely for authentication.
        </p>
        <p>
          For specific api and library documentation, please refer to one of the
          following links:
        </p>
      </section>
      <ul>
        {specs.map((s) => (
          <Spec key={s.name} name={s.name} title={s.title} />
        ))}
        {pages.map((page) => (
          <li key={page.frontmatter.path}>
            <Link to={page.frontmatter.path}>{page.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
      <hr />
      <main dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allOpenApiSpec {
      edges {
        node {
          name
          title
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { path: { ne: "index" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: "index" } }) {
      html
    }
  }
`
export default Home
